<template lang="pug">
div.row.mt-4
  ChangePassword.col-md-12.col-lg-3.ma-2
  GoogleAuthenticator.col-md-12.col-lg-4.ma-2
  ListDevice.col-md-12.col-lg-3.ma-2
</template>

<script>
export default {
  name: 'SettingsUser',
  components: {
    ChangePassword: () => import('./ChangePassword.vue'),
    GoogleAuthenticator: () => import('./TOTP/GoogleAuthenticator.vue'),
    ListDevice: () => import('./TOTP/ListDevice.vue')
  }
}
</script>
